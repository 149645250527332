document.addEventListener('DOMContentLoaded', function() {
    // Handle hamburger menu toggle
    const hamburger = document.querySelector('.js-hamburger');
    const mobileNav = document.querySelector('.mobile-navigation');

    if (hamburger && mobileNav) {
        hamburger.addEventListener('click', function() {
            hamburger.classList.toggle('is-active');
            mobileNav.classList.toggle('active');

            // Update aria-expanded attribute
            const isExpanded = hamburger.classList.contains('active');
            hamburger.setAttribute('aria-expanded', isExpanded);

            // Prevent body scroll when menu is open
            document.body.classList.toggle('menu-open', isExpanded);
        });
    }

    // Handle submenu toggles in mobile navigation
    const submenuToggleButtons = document.querySelectorAll('.mobile-navigation .submenu-toggle');

    submenuToggleButtons.forEach(button => {
        const submenu = button.closest('li').querySelector('.submenu');
        const arrow = button.querySelector('svg');

        button.addEventListener('click', function(e) {
            e.preventDefault();

            // Toggle submenu visibility
            const isExpanded = submenu.style.display === 'flex';
            submenu.style.display = isExpanded ? 'none' : 'flex';

            // Update aria-expanded attribute
            button.setAttribute('aria-expanded', !isExpanded);

            // Rotate arrow icon
            if (arrow) {
                arrow.style.transform = isExpanded ? '' : 'rotate(180deg)';
            }
        });
    });

    // Handle notification close button
    const notificationCloseBtn = document.querySelector('.js-close-notification');
    const notification = document.getElementById('notification');

    if (notificationCloseBtn && notification) {
        notificationCloseBtn.addEventListener('click', function() {
            notification.style.display = 'none';
        });
    }
});
