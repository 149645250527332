// Our main CSS
import '../css/app.css'
import './mobilenavigation'

if (document.getElementsByClassName('marquee-section')) {
  import('./marquee').then((module) => {
    module.default()
  })
}

if (document.getElementById('products')) {
  import('./products').then((module) => {
    module.default()
  })
}

if (document.getElementById('news')) {
  import('./news').then((module) => {
    module.default()
  })
}
if (document.getElementById('countup')) {
  import('./countup').then((module) => {
    module.default()
  })
}

if (document.getElementById('js-hero-video-element')) {
  import('./herovideo').then((module) => {
    module.default()
  })
}

if (document.getElementById('rolling-text')) {
  import('./rolluptext').then((module) => {
    module.default()
  })
}

if (document.getElementById('product-slider')) {
  import('./productslider').then((module) => {
    module.default()
  })
}

if (document.getElementById('accordions')) {
  import('./accordion').then((module) => {
    module.default()
  })
}

if (document.getElementById('notification')) {
  import('./notification').then((module) => {
    module.default()
  })
}

if (document.getElementById('contactform')) {
  import('./contactform').then((module) => {
    module.default()
  })
}

const industrySelectorShowBtn = document.querySelectorAll('.js-industry-selector-show')

if (industrySelectorShowBtn) {
  industrySelectorShowBtn.forEach((btn) => {
    btn.addEventListener('click', function () {
      //Add class to parent
      this.closest('.industry-selector-parent').classList.toggle('is-open')
    })
  })
}

// Set body padding height based on navigation bar height
const navigationBar = document.querySelector('.navigation-bar')
const mobileNavigation = document.querySelector('.mobile-navigation')
const bodyFrontend = document.querySelector('body.frontend')


if (navigationBar) {
  const navHeight = navigationBar.offsetHeight
  bodyFrontend.style.paddingTop = `${navHeight}px`
}

if (mobileNavigation && navigationBar) {
  setTimeout(() => {
      const navHeight = navigationBar.offsetHeight
      mobileNavigation.style.top = `${navHeight}px`
  }, 200);
}

const navigationItem = document.querySelectorAll('.navigation-item')
const navigationSubMenu = document.querySelectorAll('.navigation-submenu')

if (navigationItem) {
  navigationItem.forEach((item) => {
    let leaveTimeout
    const submenu = item.querySelector('.navigation-submenu')

    item.addEventListener('mouseenter', function () {
      // Clear any existing timeout to prevent removal if mouse re-enters quickly
      clearTimeout(leaveTimeout)
      this.classList.add('navigation-item--hover')
    })

    item.addEventListener('mouseleave', function (event) {
      // Check if the mouse is moving to the submenu
      if (submenu && submenu.contains(event.relatedTarget)) {
        return // Don't remove hover if moving to submenu
      }

      // Store reference to 'this' because it changes in the timeout callback
      const element = this
      leaveTimeout = setTimeout(() => {
        // Check again if submenu is being hovered before removing class
        if (submenu && (submenu === document.activeElement || submenu.matches(':hover'))) {
          return
        }
        element.classList.remove('navigation-item--hover')
      }, 100)
    })

    // Add listeners to submenu if it exists
    if (submenu) {
      submenu.addEventListener('mouseenter', function () {
        clearTimeout(leaveTimeout)
        item.classList.add('navigation-item--hover')
      })

      submenu.addEventListener('mouseleave', function () {
        const element = item
        leaveTimeout = setTimeout(() => {
          element.classList.remove('navigation-item--hover')
        }, 100)
      })
    }
  })
}
